<template>
    <v-dialog scrollable :value="dialog" persistent max-width="1800">
        <v-card id="dnb_dialog" height="80vh" data-nw="dunsCard">
            <v-card-title>
                <v-col lg="10">
                    DnB Input & Settings
                </v-col>
                <v-col lg="2">
                    <v-btn class="primary--text float-right mt-3"
                           text
                           @click.stop="closeDialog"
                           data-nw="closeDunsBtn"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <v-select
                            label="Minimum Confidence"
                            :items="minimumConfidence"
                            v-model="minimumConfidenceValue"
                            name="minimumConfidence"
                            @change="updateValue(`minimumConfidence`, $event)"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-select
                            label="Maximum Results"
                            :items="maximumResults"
                            v-model="maximumResultsValue"
                            name="maxResults"
                            @change="updateValue(`maxResults`, $event)"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-checkbox
                            v-model="hideOutOfBusiness"
                            label="Hide Out of Business"
                            false-value=""
                            true-value="Exclude Out of Business"
                            name="excludeOutOfBusiness"
                            @change="updateValue(`excludeOutOfBusiness`, $event)"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="Company name"
                            @input="updateValue('company_name', $event)"
                            name="company_name"
                            :value="formValueByFieldName(`company_name`)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="Address"
                            @input="updateValue('address', $event)"
                            name="address"
                            :value="formValueByFieldName(`address`)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-checkbox
                            label="Hide Undeliverable"
                            false-value=""
                            true-value="Exclude Undeliverable"
                            name="excludeUndeliverable"
                            @change="updateValue(`excludeUndeliverable`, $event)"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="Zipcode"
                            @input="updateValue('zipcode', $event)"
                            name="zipcode"
                            :value="formValueByFieldName(`zipcode`)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="City"
                            @input="updateValue('city', $event)"
                            name="city"
                            :value="formValueByFieldName(`city`)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-checkbox
                            label="Hide Non-Headquarters"
                            false-value=""
                            true-value="Exclude Non HeadQuarters"
                            name="excludeNonHeadquarters"
                            @change="updateValue(`excludeNonHeadquarters`, $event)"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="State/Province"
                            @input="updateValue('territory', $event)"
                            name="territory"
                            :value="formValueByFieldName(`province`)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-select
                            label="Country"
                            @input="updateValue('country_code', $event)"
                            name="country_code"
                            :value="formValueByFieldName(`country_code`)"
                            :items=countries
                            item-text="name"
                            v-model="selectedCountryCode"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-checkbox
                            label="Hide Non-Marketable"
                            false-value=""
                            true-value="Exclude Non Marketable"
                            name="excludeNonMarketable"
                            @change="updateValue(`excludeNonMarketable`, $event)"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="DUNS Number"
                            @input="updateValue('dunsNumber', $event)"
                            name="dunsNumber"
                            :value="formValueByFieldName(`duns_number`)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="Phonenumber"
                            @input="updateValue('phoneNumber', $event)"
                            name="phoneNumber"
                            :value="formValueByFieldName(`phonenumber`)"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-checkbox
                            label="Hide Unreachable"
                            false-value=""
                            true-value="Exclude Unreachable"
                            name="excludeUnreachable"
                            @change="updateValue(`excludeUnreachable`, $event)"
                        ></v-checkbox>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="4" class="py-0">
                        <v-select
                            label="Select company number"
                            item-text="label"
                            item-value="value"
                            :items="companyNumberOptions"
                            v-model="companyNumberOption"
                            @change="valueByCompanyCodeOption()"
                            name="companyNumberSelect"
                        ></v-select>
                    </v-col>
                    <v-col cols="4" class="py-0">
                        <v-text-field
                            label="Company number"
                            @blur="updateCompanyNumberValue()"
                            name="companyNumber"
                            v-model="companyNumberValue"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                        id="perform-dnb-search-button"
                        class="text-capitalize my-3 ml-10 mr-3"
                        rounded color="primary"
                        @click="dnbSearch()"
                    >
                        Perform DnB Search
                    </v-btn>
                </v-row>
                <v-card-title>
                    DnB Search Results
                </v-card-title>
                <v-data-table
                    v-if="searchedForResults"
                    id="dnb_data_table"
                    :loading="loading"
                    :sort-desc="true"
                    :headers="headers"
                    :items="dnbMatches"
                    :items-per-page="-1"
                    v-model="selected"
                    show-select
                    single-select
                    item-key="dnbDunsNumber"
                    hide-default-footer
                    :no-data-text="dnbResponseMessage"
                >
                </v-data-table>
                <v-row>
                    <v-spacer></v-spacer>
                    <v-btn
                        id="save-choice-button"
                        class="text-capitalize my-3 ml-10 mr-3 float-right"
                        rounded color="primary"
                        :disabled="dnbMatchSelected"
                        @click="saveDnbMatchToForm()">
                        Match with selected
                    </v-btn>
                </v-row>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import countries from "../../../assets/countries";
import importedHeaders from "@/components/views/dialogs/headers/DnbDialogHeaders.json";

export default {
    "props": {"dialog": {"default": false}},
    "computed": {
        searchedForResults () {
            return this.dnbMatches !== null;
        }
    },
    "methods": {
        updateValue (name, value) {
            this.$store.dispatch("applicationFormValues/updateDnbSearchParams", {
                name,
                value
            });
        },
        valueByCompanyCodeOption () {
            if (this.companyNumberOption === "none") {
                this.companyNumberValue = "";
            } else {
                this.companyNumberValue = this.$store.state.applicationFormValues.values[this.companyNumberOption];
            }
            this.updateCompanyNumberValue();
        },
        updateCompanyNumberValue () {
            this.$store.dispatch("applicationFormValues/updateDnbSearchParams", {
                "name": "companyNumber",
                "value": this.companyNumberValue
            });
        },
        formValueByFieldName (fieldName) {
            if (fieldName === "country_code") {
                this.selectedCountryCode = this.$store.state.applicationFormValues.values.country;
            }
            return this.$store.state.applicationFormValues.values[fieldName];
        },
        async dnbSearch () {
            this.loading = true;
            this.dnbMatches = [];
            await this.$store.dispatch("applicationFormValues/dnbLookup");
            const matches = this.$store.state.applicationFormValues.dnbMatches;
            if (matches !== null) {
                this.dnbMatches = this.$store.state.applicationFormValues.dnbMatches;
            } else {
                this.dnbResponseMessage = this.$store.state.applicationFormValues.dnbResponseMessage;
                this.dnbMatches = [];
            }
            this.loading = false;
        },
        closeDialog () {
            this.$emit("update:dialog", false);
        },
        saveDnbMatchToForm () {
            const dnbMatch = this.selected[0];
            const dnbResult = "Look Up was done manually";
            this.$store.dispatch("applicationFormValues/updateMatchedDnB", {dnbMatch});
            this.$store.dispatch("applicationFormValues/setAutoDnbLookUpResult", {dnbResult});
            this.$emit("update:dialog", false);
        }
    },
    "watch": {
        selected () {
            this.dnbMatchSelected = this.selected.length === 0;
        }
    },
    // eslint-disable-next-line max-lines-per-function
    "data" () {
        return {
            "loading": false,
            countries,
            "dnbMatches": null,
            "dnbResponseMessage": null,
            "selected": [],
            "dnbMatchSelected": true,
            "selectedCountryCode": "",
            "minimumConfidence": ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
            "companyNumberOptions": [
                { "label": "VAT Number", "value": "vat_number" },
                { "label": "CoC Number", "value": "coc_nr" },
                { "label": "None", "value": "none" }
            ],
            "companyNumberOption": "",
            "companyNumberValue": "",
            "maximumResults": ["5", "10", "25"],
            "minimumConfidenceValue": "5",
            "maximumResultsValue": "10",
            "hideOutOfBusiness": "Exclude Out of Business",
            "headers": importedHeaders
        };
    }
};
</script>
